import React                                               from 'react';
import {Paragraph}                                         from "src/components/Typography";
import {Container, Description, StyledLi, StyledUl, Title} from "./styles";

export const List = ({data}) => {
  return (
    <Container>
      <Title>{data.title}</Title>
      <Paragraph>{data.text}</Paragraph>
      <StyledUl>
        {data.content.map((element, i) => (
          <StyledLi key={i}>
            <Description>{element}</Description>
          </StyledLi>
        ))}
      </StyledUl>
    </Container>
  );
}
