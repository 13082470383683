import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 72px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    .gatsby-image-wrapper {
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding: 0 24px 32px 24px;
    }
`;
