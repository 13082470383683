import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 72px;
    max-width: ${({width}) => width ? `${width}px` : "1000px"};
    margin: 0 auto;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding: 0 24px 32px 24px;
    }
`;

export const ImageCaption = styled.p`
    font-size: 1.4rem;
    color: #717171;
    text-align: center;
    font-weight: 400;
    max-width: 600px;
    margin: 24px auto;

    a {
        &:hover {
            color: ${({theme}) => theme.colors.purple};
            text-decoration: underline;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop}) {
        margin: 12px auto;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        max-width: 320px;
    }
`;


