import {Author, Hero, ImageWithCaption, Layout, List, Text} from "components/BlogLayout";
import SEO                                                  from "components/seo";
import React                                                from "react";
import {useArticlesData}                                    from "shared/Hooks/Articles/useArticlesData";
import {ARTICLE_15}                                         from "shared/Types/articles";

const Article_15 = () => {
  const data = useArticlesData(ARTICLE_15);

  return (
    <Layout>
      <SEO title={data.SEO_title} description={data.seo} image={data.image}/>
      <Hero data={data}/>
      <Text data={data.text.first}/>
      <ImageWithCaption data={data.imagesWithCaption.first}/>
      <List data={data.list.first}/>
      <Text data={data.text.third}/>
      <ImageWithCaption data={data.imagesWithCaption.second}/>
      <Text data={data.text.fourth}/>
      <ImageWithCaption data={data.imagesWithCaption.third}/>
      <Text data={data.text.fifth}/>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_15;
