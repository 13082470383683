import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 48px;
    max-width: 700px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-bottom: 24px;
    }
`;

export const StyledIframe = styled.iframe`
    width: 100% !important;
    height: 400px !important;
    border: none !important;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_big}) {
        height: 400px !important;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 350px !important;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        height: 250px !important;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        height: 200px !important;
    }
`;
