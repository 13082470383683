import React from "react";
import {
  Container,
  Wrapper,
  Column,
  Date,
  Category,
  Title,
  ImageWrapper,
  DescriptionWrapper,
  Description,
} from "./styles";

export const Hero = ({ data }) => {
  return (
    <>
      <Container>
        <Wrapper>
          <Column>
            <Date>{data.date}</Date>
            <Category>{data.category}</Category>
            <Title>{data.title}</Title>
          </Column>
          <Column>
            <ImageWrapper>{data.hero}</ImageWrapper>
          </Column>
        </Wrapper>
      </Container>
      <DescriptionWrapper>
        <Description>{data.description}</Description>
      </DescriptionWrapper>
    </>
  );
};
