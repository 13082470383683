import React from 'react';
import {Container, AuthorBox, AuthorImgWrap, AuthorSmallWrapper, Name, Position, AuthorTitle} from './styles'
import uuid from "react-uuid";

const Author = ({data}) => {
  return (
    <Container>
      {data.map(author =>
        <AuthorBox className={data.length > 1 ? 'half-width': ''} key={uuid()}>
          <AuthorImgWrap size="56px">
            {author.image}
          </AuthorImgWrap>
          <AuthorSmallWrapper>
            <AuthorTitle>Autor</AuthorTitle>
            <Name>{author.name}</Name>
            <Position>{author.position}</Position>
          </AuthorSmallWrapper>
        </AuthorBox>
      )}
    </Container>
  );
}

export {Author};
