import styled from "styled-components";
import {TextWrapper} from "shared/Styles/Wrappers";

export const Container = styled(TextWrapper)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 1px solid #ccc;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_small}) {
        flex-direction: column;
        align-items: center;
    }
`

export const AuthorBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    width: 100%;
    height: 100%;

    &.half-width {
        width: 50%;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        flex-direction: column;

    }
`;

export const AuthorImgWrap = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 100%;
    box-shadow: 0 0 8px 2px ${({theme}) => theme.colors.box_shadow};
    .gatsby-image-wrapper {
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 50px;
        height: 50px;
    }
`;

export const AuthorSmallWrapper = styled.div`
    margin-left: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        margin-left: 0;
        margin-top: 12px;
    }
`;

export const Name = styled.span`
    color: ${({color, theme}) => (color ? color : theme.colors.black)};
    font-size: ${({theme}) => theme.fonts.s};
    font-weight: 700;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: ${({theme}) => theme.fonts.xs};
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.xxs};
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        text-align: center;
    }
`;

export const AuthorTitle = styled.span`
    font-size: 1.2rem;
    margin-bottom: 4px;
    color: ${({theme}) => theme.colors.black};
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        text-align: center;
    }
`

export const Position = styled.span`
    color: ${({theme}) => theme.colors.gray_text};
    font-size: 1.2rem;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 1rem;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        text-align: center;
    }
`;
