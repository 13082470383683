import styled from 'styled-components'
import {TextWrapper} from "shared/Styles/Wrappers";
import {Paragraph} from "components/Typography";

export const Container = styled(TextWrapper)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({reverse}) => reverse ? 'row-reverse' : ""};
    padding-bottom: 72px;
    margin: 0 auto;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding-bottom: 32px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        flex-direction: column;
        align-items: center;
    }
`

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 30%;

    .gatsby-image-wrapper {
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        margin-bottom: 32px;
        order: -1;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) {
        width: 40%;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) and (orientation: landscape) {
        width: 30%;
    }
`

export const TextBox = styled.div`
    width: 70%;
    padding: ${({reverse}) => reverse ? '0 0 0 96px' : '0 96px 0 0'};

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        width: 100%;
        padding: 0;
    }
`
export const Title = styled.h2`
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 14px;
    font-size: 20px;
    color: ${({theme}) => theme.colors.black};
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 18px;
        margin-bottom: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 16px;
    }
`;

export const Description = styled(Paragraph)`
    b {
        font-weight: 600;
        color: ${({theme}) => theme.colors.purple};
    }
`;
