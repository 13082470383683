import {Paragraph}   from "components/Typography";
import {TextWrapper} from "shared/Styles/Wrappers";
import styled        from "styled-components";

export const Container = styled(TextWrapper)`
    padding-bottom: 72px;
    margin: 0 auto;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding-bottom: 32px;
    }
`;

export const Title = styled.h2`
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 14px;
    font-size: 20px;
    color: ${({theme}) => theme.colors.black};
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 18px;
        margin-bottom: 12px;
    }
`

export const StyledUl = styled.ul`
    margin-top: 24px;
`

export const StyledLi = styled.li`
    padding-left: 32px;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: ${({theme}) => theme.colors.purple};
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding-left: 24px;
        &:before {
            width: 4px;
            height: 4px;
            top: 8px;
        }
    }
`

export const Description = styled(Paragraph)`
    b {
        font-weight: 600;
        color: ${({theme}) => theme.colors.purple};
    }

    strong {
        font-weight: 600;
    }
`;
