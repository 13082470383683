import styled from "styled-components";
import {TextWrapper} from "shared/Styles/Wrappers";
import {Paragraph} from "components/Typography";

export const Container = styled.div`
    padding: 90px 24px 0 24px;
    width: 100%;
    background-color: ${({theme}) => theme.colors.blog_background};

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        padding-left: 0;
        padding-right: 0;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        padding-top: 72px;
    }
`;

export const Wrapper = styled(TextWrapper)`
    display: flex;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

export const Column = styled.div`
    width: 50%;
    display: flex;

    &:nth-child(1) {
        justify-content: center;
        flex-direction: column;
        color: ${({theme}) => theme.colors.black};
    }

    &:nth-child(2) {
        justify-content: flex-end;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        &:nth-child(2) {
            justify-content: center;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        width: 100%;
        justify-content: center;
    }
`;

export const Date = styled.span`
    font-size: 14px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 10px;
    }
`;

export const Category = styled.span`
    font-size: 14px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 10px;
    }
`;

export const Title = styled.h1`
    font-weight: 800;
    line-height: 1.2;
    max-width: 560px;
    margin-top: 10px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 24px;
        max-width: 420px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 20px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        font-size: 20px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
        max-width: 380px;
    }
`;

export const ImageWrapper = styled.div`
    width: 400px;

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        width: 310px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 266px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 230px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 180px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        width: 240px;
    }
`;

export const DescriptionWrapper = styled(TextWrapper)`
    padding-top: 64px;
    padding-bottom: 64px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
`;

export const Description = styled(Paragraph)`
    font-weight: 600;
`;
