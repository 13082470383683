import React from "react";
import { Container, Title, Description } from "./styles";

export const Text = ({ data }) => {
  return (
    <Container>
      {data.title && <Title>{data.title}</Title>}
      {data.text && <Description>{data.text}</Description>}
    </Container>
  );
};
