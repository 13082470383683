import {Paragraph}   from "components/Typography";
import {TextWrapper} from "shared/Styles/Wrappers";
import styled        from "styled-components";

export const Container = styled(TextWrapper)`
    padding-bottom: 72px;
    margin: 0 auto;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding-bottom: 32px;
    }

`;

export const Title = styled.h2`
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 14px;
    font-size: 20px;
    color: ${({theme}) => theme.colors.black};
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 18px;
        margin-bottom: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 16px;
    }
`;

export const Description = styled(Paragraph)`
    a {
        text-decoration: underline;
        transition: .4s color ease;

        &:hover {
            color: ${({theme}) => theme.colors.purple};

        }
    }

    b {
        font-weight: 600;
        color: ${({theme}) => theme.colors.purple};
    }
`;
