import React from 'react';
import { Container, Title, Description, TextBox, ImageWrapper } from "./styles";

const TextImage = ({data, image, reverse}) => {
  return (
    <Container reverse={reverse}>
      <TextBox reverse={reverse}>
        {data.title && <Title>{data.title}</Title>}
        {data.text && <Description>{data.text}</Description>}
      </TextBox>
      <ImageWrapper>
        {image}
      </ImageWrapper>
    </Container>
  );
}

export {TextImage};
